import { sortByMultiple } from "@biblioteksentralen/utils";
import { getNorwegianDateNow, getNorwegianTimeNow } from "@libry-content/common";
import { LanguageCode } from "@libry-content/localization";
import { ArrayElement, customizableRepeatedEventFields } from "@libry-content/types";
import { assign, last, pick, sort } from "radash";
import { formatDate } from "../../utils/date";
import { DateHelper } from "@libry-content/common";
import { ResolvedEvent } from "./sanityQuery";
import isValid from "date-fns/isValid";

export type Occurrence = ArrayElement<NonNullable<ResolvedEvent["repeatedDates"]>>;

export type EventOccurrence = ResolvedEvent & { occurrence?: Occurrence };

export const isEventOccurrence = (
  event: Pick<ResolvedEvent, "_id"> | Pick<EventOccurrence, "occurrence">
): event is EventOccurrence => !!(event as any)?.["occurrence"]?.["_key"];

export const eventTimeString = (event: ResolvedEvent): string =>
  event.eventEnd ? `${event.eventStart} - ${event.eventEnd}` : event.eventStart ?? "";

export const isThisYear = (dateString: string | undefined): boolean =>
  dateString ? new Date(dateString).getFullYear() === new Date().getFullYear() : true;

export const getDateStringFormat = (showYear: boolean, allDay?: boolean): string => {
  if (allDay) return showYear ? "PP" : "E do MMMM";
  return showYear ? "E PPP" : "E do MMMM";
};

// This assumes repeated events have aquired a startDate using resolveEventGroqProjection,
// otherwise it would need extra handling
export const eventDateString = (languageCode: LanguageCode, event: ResolvedEvent | EventOccurrence): string => {
  const showYear = !isThisYear(event.startDate) || !isThisYear(event.endDate);
  const singleDayFormat = getDateStringFormat(showYear);
  const allDayFormat = getDateStringFormat(showYear, true);

  if (event.allDay && event.startDate !== event.endDate) {
    const startDateString = formatDate(languageCode, allDayFormat, event.startDate);
    const endDateString = formatDate(languageCode, allDayFormat, event.endDate);
    return `${startDateString} til ${endDateString}`;
  }

  return formatDate(languageCode, singleDayFormat, event.startDate);
};

export const isInFuture = (occurence: Occurrence, event: Pick<ResolvedEvent, "eventEnd">): boolean =>
  !!occurence.date &&
  (occurence.date > getNorwegianDateNow() ||
    (occurence.date == getNorwegianDateNow() &&
      (occurence.customization?.eventEnd ?? event.eventEnd ?? "00:00") > getNorwegianTimeNow()));

const dateToDateTime = (dateString: string | undefined) => (dateString ? `${dateString}` : undefined);

const getDateAsSeconds = (dateString: string | undefined) => new Date(dateToDateTime(dateString) ?? "").getTime();

const isValidDate = (dateString: string | undefined) => isValid(new Date(dateString ?? ""));

const repeatedEventLastOccurrence = (event: Pick<ResolvedEvent, "repeatedDates">): Occurrence | undefined =>
  last(
    sort(event?.repeatedDates?.filter(({ date }) => isValidDate(date)) ?? [], ({ date }) => getDateAsSeconds(date))
  ) ?? undefined;

export const eventIsFinished = ({ allDay, endDate, startDate, repeated, repeatedDates }: ResolvedEvent): boolean => {
  if (repeated) {
    const lastOccurrence = repeatedEventLastOccurrence({ repeatedDates });
    return !!lastOccurrence?.date && !new DateHelper(lastOccurrence?.date).isTodayOrLater;
  }

  if (allDay && endDate) {
    return !new DateHelper(endDate).isTodayOrLater;
  }

  return !!startDate && !new DateHelper(startDate).isTodayOrLater;
};

export const eventIsOpen = (eventStatus: ResolvedEvent["eventStatus"]): boolean =>
  eventStatus === undefined || eventStatus === "available";

export const eventIsCancelled = (eventStatus: ResolvedEvent["eventStatus"]) => eventStatus === "cancelled";

export const eventIsFull = (eventStatus: ResolvedEvent["eventStatus"]): boolean => eventStatus === "full";

// For objects we need a deep merge so that e.g. empty custom localized fields don't overwrite default content.
// For arrays we want to overwrite so that portable text does not combine default content and customization.
// radash's `assign()` does exactly this.
export const applyRepeatedEventCustomization = <T extends EventOccurrence>(event: T): T =>
  assign(
    event,
    event.occurrence?.customization ? pick(event.occurrence?.customization, customizableRepeatedEventFields) : {}
  ) as T;

export const createEventOccurrence = (event: ResolvedEvent, occurrence: Occurrence): EventOccurrence =>
  applyRepeatedEventCustomization({
    ...event,
    startDate: occurrence.date,
    occurrence,
  });

const getTimeAsMinutes = (time: string | undefined) => {
  const [hoursString, minutesString] = time?.split(":") ?? [];
  const hours = isNaN(Number(hoursString)) ? 0 : Number(hoursString);
  const minutes = isNaN(Number(minutesString)) ? 0 : Number(minutesString);
  return hours * 60 + minutes;
};

const hasValidStartDate = ({ startDate }: ResolvedEvent) => isValidDate(startDate);

export const expandRepeatedEvents = (
  events: ResolvedEvent[] = [],
  arePastEvents?: boolean
): (ResolvedEvent | EventOccurrence)[] =>
  sortByMultiple(
    events
      .flatMap((event) =>
        event.repeated
          ? event.repeatedDates
              ?.filter((occurence) => (arePastEvents ? !isInFuture(occurence, event) : isInFuture(occurence, event)))
              ?.map((occurrence) => createEventOccurrence(event, occurrence)) ?? []
          : [event]
      )
      .filter(hasValidStartDate),
    ({ startDate }) => getDateAsSeconds(startDate) * (arePastEvents ? -1 : 1),
    ({ eventStart }) => getTimeAsMinutes(eventStart) * (arePastEvents ? -1 : 1)
  );

export const looksLikeEmail = (registrationUrlOrEmail: string | undefined) => !!registrationUrlOrEmail?.includes("@");
